import React, { FunctionComponent, ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { LocationProvider } from '../hooks/useLocation'
import ToastWrapper from '../components/ToastWrapper'
import Navbar from '../components/Navbar'
import SubNavbar from '../components/SubNavbar'
import Footer from '../components/Footer/Footer'
import StateProvider from '../components/StateProvider/StateProvider'
import { HEADER_ID, MAIN_CONTAINER_ID, MW_APP_HIDE_CLASSNAME } from '../utils/constants'
import BackToTopButton from '../components/BackToTopButton'
import { GatsbyLocation } from '../utils/types'

interface Props {
  children: ReactNode
  location: GatsbyLocation
}
const Layout: FunctionComponent<Props> = ({ children, location }: Props) => {
  const displayUserSnap = process.env.GATSBY_USERSNAP_ENABLED === 'true'
  return (
    <StateProvider>
      <LocationProvider router={location}>
        <ToastWrapper />
        <Helmet>
          {displayUserSnap && (
            <script>
              {`
              window.onUsersnapCXLoad = function(api) {
                api.init();
                if (api.show) api.show('13909f1a-282b-4b10-8b19-57ff314b0c57')
              }
              var script = document.createElement('script');
              script.defer = 1;
              script.src = 'https://widget.usersnap.com/load/13909f1a-282b-4b10-8b19-57ff314b0c57?onload=onUsersnapCXLoad';
              document.getElementsByTagName('head')[0].appendChild(script);
            `}
            </script>
          )}
          <script async defer src="https://use.fortawesome.com/d04e4972.js" />
        </Helmet>
        <div id={HEADER_ID} className={MW_APP_HIDE_CLASSNAME}>
          <Navbar />
        </div>
        <SubNavbar />
        <BackToTopButton />
        <div id={MAIN_CONTAINER_ID} className="flex-grow">{children}</div>
        <Footer />
      </LocationProvider>
    </StateProvider>
  )
}

export default Layout
