import './src/styles/global.css'
import Cookies from 'js-cookie'
import {
  MW_APP_BODY_CLASSNAME,
  MW_APP_HIDE_COOKIE,
  MW_APP_UTM_SOURCE,
  ZARAZ_CONSENT_COOKIE
} from './src/utils/constants'

/**
 * Implement Gatsby's Browser APIs in this file.
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

/**
 * Triggered when a route change happens
 * @returns {void}
 */
export const onRouteUpdate = () => {
  if (typeof window !== 'undefined') {
    // timeout needed to make sure we get the correct title.
    // without the timeout we get the title of the previous page
    setTimeout(() => {
      if (typeof window === 'undefined') return
      // If zaraz exists, trigger a page view
      if (typeof window.zaraz !== 'undefined') {
        window.zaraz.spaPageview()
      }
    }, 500)
  }
}

export const onClientEntry = () => {
  // exit if we don't have a window
  if (typeof window === 'undefined') return

  /**
   * NOTE: ideally we should be looking at headers, but we would need access to the
   * http request for that. This is the quick-and-dirty way to determine if the request
   * is from the mobile app
   */
  const search = new window.URLSearchParams(window.location.search)
  const utmSource = search.get('utm_source')
  const existingCookie = Cookies.get(MW_APP_HIDE_COOKIE)

  if (utmSource === MW_APP_UTM_SOURCE && !existingCookie) {
    // expires one day from now
    Cookies.set(MW_APP_HIDE_COOKIE, 'true', { expires: 1, path: '/' })
  }

  if (utmSource === MW_APP_UTM_SOURCE || existingCookie === 'true') {
    document.body.classList.add(MW_APP_BODY_CLASSNAME)
  }

  /**
   * Zaraz setup
   */
  function handleZarazConsentAPIReady() {
    if (typeof window.zaraz === 'undefined' || typeof window.zaraz.consent === 'undefined') return
    const consentCookie = Cookies.get(ZARAZ_CONSENT_COOKIE)
    const gpcValue = navigator.globalPrivacyControl
    if (!consentCookie) {
      if (gpcValue) {
        zaraz.consent.setAll(false)
        zaraz.consent.sendQueuedEvents()
      } else {
        zaraz.consent.setAll(true)
        zaraz.consent.sendQueuedEvents()
      }
    }
  }

  if (typeof window.zaraz !== 'undefined' && window.zaraz.consent?.APIReady) {
    handleZarazConsentAPIReady()
  } else {
    document.addEventListener('zarazConsentAPIReady', handleZarazConsentAPIReady)
  }
}
