import classNames from 'classnames'
import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import backToTop from '../static/assets/back-to-top.svg'
import { scrollToTop } from '../utils'
import { StateContext } from './StateProvider/StateContext'

const footerOffset = 200
const scrollHeightOffset = 2000
const scrollYOffset = 200

export const BackToTopButton: FunctionComponent = () => {
  const [show, setShow] = useState(false)
  const [atBottom, setAtBottom] = useState(false)
  const { breakpoints } = useContext(StateContext)

  useEffect(() => {
    const displayBackToTopBtn: () => void = () => {
      setShow(false)
      if (document) {
        if (document.body.scrollHeight > scrollHeightOffset) {
          const bottom = document.body.scrollHeight - window.innerHeight - footerOffset
          if (window.scrollY > scrollYOffset) {
            setShow(true)
          } else {
            setShow(false)
          }

          if (window.scrollY >= bottom) {
            setAtBottom(true)
          } else {
            setAtBottom(false)
          }
        }
      }
    }
    window.addEventListener('scroll', displayBackToTopBtn)
    return () => window.removeEventListener('scroll', displayBackToTopBtn)
  }, [])

  return (
    <button
      onClick={scrollToTop}
      type="button"
      className={classNames(
        { 'opacity-100': show },
        { 'pointer-events-none invisible': !show },
        { 'bottom-56': atBottom && breakpoints.md },
        { absolute: atBottom && breakpoints.md },
        'opacity-0',
        'transition-opacity',
        'bottom-4',
        'fixed',
        'z-30',
        'right-4',
        'bg-pink',
        'rounded-lg',
        'p-2',
      )}
    >
      <img src={backToTop} alt="Back to top" />
    </button>
  )
}

export default BackToTopButton
